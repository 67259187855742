@font-face {
    font-family: CustomFont;
    src: url(fonts/font4.otf) format('opentype');
  }
  

body {
    margin: 0;
    font-family: CustomFont, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: darkgrey;
    color:black
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }
  a{
    color:blue;
    text-decoration: none;
  }
  
  .navbar {
    padding: 20px;
    display: flex;
    align-items: center;
    max-width: 600px;
    margin: 0 auto;
    border-bottom: 1px solid #f2f2f2;
  }
  
  .navbar a {
    text-decoration: none;
    margin-right: 90px;
    color: black
  }
  

  .hide-cursor {
    caret-color: transparent;
}
.container {
    display: flex;
    justify-content: center; 
    height: 100vh; 
  }
  .centered{
    justify-content: center;
    display: flex;

  }
.column {
    max-width: 600px; 
    display: flex;
    flex-direction: column;
}

.content-wrapper {
  display: flex;
}
.image-wrapper {
  flex-shrink: 0;
  margin-right: 20px; 
  float:left;
}
.text-wrapper {
  flex: 1;
}
.homePage{
  max-width: 600px;
}
